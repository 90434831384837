import axios from '@/libs/axios'

export function reqGetTasks(payload) {
  return axios.get('api/admin/tasks', { params: payload })
}
export function reqGetTrainingTask(id) {
  return axios.get(`api/admin/trainings/${id}/task`)
}
export function reqGetChapterTask(id) {
  return axios.get(`api/admin/chapters/${id}/task`)
}
export function reqGetTask(id) {
  return axios.get(`api/admin/tasks/${id}`)
}
export function reqCreateTrainingTask(trainingId, payload) {
  return axios.post(`api/admin/trainings/${trainingId}/tasks`, payload)
}
export function reqCreateChapterTask(chapterId, payload) {
  return axios.post(`api/admin/chapters/${chapterId}/tasks`, payload)
}
export function reqUpdateTask(id, payload) {
  return axios.post(`api/admin/tasks/${id}?_method=PATCH`, payload)
}
export function reqDeleteTask(id) {
  return axios.delete(`api/admin/tasks/${id}`)
}

export function reqGetTaskResults(payload) {
  return axios.get('api/admin/task-results', { params: payload })
}
export function reqDeleteTaskResult(id) {
  return axios.delete(`api/admin/task-results/${id}`)
}
export function reqGetEssayQuestionAnswers(id) {
  return axios.get(`api/admin/task-results/${id}/essay-question-answers`)
}
export function reqSaveEssayQuestionCorrection(id, payload) {
  return axios.post(`api/admin/task-results/${id}/correction`, payload)
}
